/******************
   Recaptcha
******************/

function recaptchaCallback() {
  jQuery('#submit').removeAttr('disabled');
};

$(document).ready(function () {

  /******************
   SHRINK
  ******************/
  $(document).on("scroll", function () {
    var distance = $('.top').offset().top + 2,
      $window = $(window);
    if ($window.scrollTop() >= distance) {
      $(".top,.siteheader,.sitenav").addClass("shrink");
    } else {
      $(".top,.siteheader,.sitenav").removeClass("shrink");
    }
  });

  /******************
     Nav Scroll
  ******************/
  $(document).on('click', '.sitenav__link', function (event) {
    event.preventDefault();

    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);



  });

  $(window).scroll(function () {
    var scrollTop = $(document).scrollTop();
    var anchors = $('body').find('section');

    for (var i = 0; i < anchors.length; i++) {
      if (scrollTop > $(anchors[i]).offset().top - 60 && scrollTop < $(anchors[i]).offset().top + $(anchors[i]).outerHeight()) {
        $('.sitenav ul li a[href="#' + $(anchors[i]).attr('id') + '"]').addClass('active');
      } else {
        $('.sitenav ul li a[href="#' + $(anchors[i]).attr('id') + '"]').removeClass('active');
      }
    }
  });
 /******************
     Numbers Only input
  ******************/
  jQuery('.numbersonly').keypress(function(event){
    if(event.which !=8 && isNaN(String.fromCharCode(event.which))){
      event.preventDefault();
    }

  });

  /******************
     Back Top
  ******************/

  if ($('.backtop').length) {
    var scrollTrigger = 800,

      backToTop = function () {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > scrollTrigger) {
          $('.backtop').addClass('show');
        } else {
          $('.backtop').removeClass('show');
        }
      };
    backToTop();
    $(window).on('scroll', function () {
      backToTop();
    });
    $('.backtop').on('click', function (e) {
      e.preventDefault();
      $('html,body').animate({
        scrollTop: 0
      }, 700);
    });
  }

  /******************
     Parallax
  ******************/

  function parallax() {
    var scrolled = $(window).scrollTop();
    $('.banners img').css({
      'transform': 'translateY(' + +(scrolled * 0.5) + 'px)',
      '-moz-transform': 'translateY(' + +(scrolled * 0.5) + 'px)',
      '-webkit-transform': 'translateY(' + +(scrolled * 0.5) + 'px)',
      '-o-transform': 'translateY(' + +(scrolled * 0.5) + 'px)'
    });
  }

  $(window).on('scroll', function () {
    parallax();
  });


  /******************
     Menu
  ******************/
  var toggle = $('.sitenav__toggle');
  var menu = $('.sitenav');
  var bod = $('body');

  $(function () {
    $(toggle).on('click', function (e) {
      e.preventDefault();
      menu.slideToggle(250);
      toggle.toggleClass('active');
      //bod.toggleClass('menuopen');
    });

  });

  $(window).resize(function () {
    var w = $(window).width();
    if (w > 768 && menu.is(':hidden')) {
      menu.removeAttr('style');
    }
  });

  /******************
     Banners
  ******************/

  $(".banners ul").lightSlider({
    item: 1,
    autoWidth: false,
    slideMove: 1,
    slideMargin: 10,

    addClass: '',
    mode: "fade",
    useCSS: true,
    cssEasing: 'ease-in-out',
    easing: 'linear',

    speed: 400,
    auto: true,
    loop: true,
    slideEndAnimation: true,
    pause: 5000,

  });

  /******************
   Banners
******************/

  $(".testimonials ul").lightSlider({
    item: 1,
    autoWidth: false,
    slideMove: 1,
    slideMargin: 10,
    adaptiveHeight: true,
    addClass: '',
    mode: "fade",
    useCSS: true,
    cssEasing: 'ease-in-out',
    easing: 'linear',
    speed: 400,
    auto: true,
    loop: true,
    slideEndAnimation: true,
    pause: 10000,
    controls: false,
    pager: false,
  });

});

/******************
     SHOW HIDE MENU
******************/

$(window).on("load resize", function (e) {
  if ($('.ismobile').is(':visible')) {
    $('.sitenav').hide();
    $('.sitenav__toggle').removeClass('active');
    $('.sitenav__link').on('click', function (e) {
      $('.sitenav').hide();
      $('.sitenav__toggle').removeClass('active');
    });
  } else if (!$('.ismobile').is(':visible')) {
    $('.sitenav__link').on('click', function (e) {
      $('.sitenav').show();
    });
  }
});

/******************
     CALENDAR
******************/
$(".calendarview").on("click", function () {
  
  $(function () {

    var calendar = $('#calendar').fullCalendar({
      themeSystem: 'bootstrap',
      //hiddenDays: [ 0 ],
      editable: false,
      displayEventTime: false,
      eventColor: '#669900',
      header: {
        left: 'prev,next today',
        center: '',
        // right: 'month,agendaWeek,agendaDay'
        right: 'title'
      },
      events: '/load',
      selectable: false,
      selectHelper: false,
      contentHeight: "auto",
      fixedWeekCount:false,

      //Add Past class to older ones

      eventRender: function (calev, elt, view) {
        var ntoday = new Date();
        if (calev.start._d.getTime() < ntoday.getTime()) {
            elt.addClass("calpast");
            elt.children().addClass("calpast");
        }
    },


      //ADD NEW EVENT - OPEN MODAL

      select: function (start, end, allDay) {

        if (start.isBefore(moment())) {
          $('#calendar').fullCalendar('unselect');
          return false;

        }



        var eventDate = $.fullCalendar.formatDate(start, "DD-MM-Y");

        var start = $.fullCalendar.formatDate(start, "Y-MM-DD HH:mm:ss");

        $('#eventDate').val(eventDate);
        $('#start').val(start);
        $('#addEvent').modal('show');
      },

      editable: false,

      //EVENT INFO - OPEN MODAL

      eventClick: function (event) {

        if (event.start.isBefore(moment())) {
          $('#calendar').fullCalendar('unselect');
          return false;
        }

        var id = event.id;
        var eventDate = $.fullCalendar.formatDate(event.start, "DD-MM-Y");

        // Need to add the date into here rather than ID I think

        window.location.href = 'trekking?t=' + id + '&d=' + eventDate;

        // window.location.href = 'trekking?t='+eventDate;

      },

    });
  });

});

/**********************
    GOOGLE MAPS API
**********************/
function initialize() {
  var myLatlng = new google.maps.LatLng(54.888504, -4.261078);
  var imagePath = '/img/marker.png'
  //SETTINGS HERE
  var mapOptions = {
    zoom: 10,
    streetViewControl: false,
    disableDefaultUI: true,
    mapTypeControl: false,
    scaleControl: true,
    zoomControl: true,
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  }
  var map = new google.maps.Map(document.getElementById('map'), mapOptions);
  //ADD THE MARKER
  var marker = new google.maps.Marker({
    position: myLatlng,
    map: map,
    icon: imagePath,
    title: 'pin'
  });
  google.maps.event.addListener(marker, 'click', function () {
    infowindow.open(map, marker);
  });
  //RESIZE & CENTER THE MAP ON THE MARKER
  google.maps.event.addDomListener(window, "resize", function () {
    var center = map.getCenter();
    google.maps.event.trigger(map, "resize");
    map.setCenter(center);
  });
}
google.maps.event.addDomListener(window, 'load', initialize);